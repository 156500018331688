<template>
    <div class="m-0 d-flex flex-wrap align-items-center mt-5" :class="mobile ? 'justify-content-center' : 'justify-content-between'">
        <h3 class="mb-4">Liste des stagiaires</h3>
        <div
            class="d-flex flex-wrap align-items-center mb-2"
            :class="mobile ? 'justify-content-center' : 'justify-content-between'"
            style="width:100%"
        >
            <b-form-group :style="mobile ? 'width:100%' : 'width:60%'" class="m-0" label-for="filterInput">
                <b-input-group>
                    <b-form-input
                        v-model.lazy="filter"
                        @change="onSearch"
                        type="search"
                        id="filterInput"
                        placeholder="Rechercher une personne avec son nom de famille"
                    />
                    <b-input-group-append>
                        <b-button :disabled="!filter" @click="filter = ''">Effacer</b-button>
                    </b-input-group-append>
                </b-input-group>
            </b-form-group>
            <router-link :to="{ name: 'trainee.create' }" class="btn btn-clean btn-primary">
                Créer un nouveau stagiaire
            </router-link>
        </div>
        <b-table
            :items="trainees.data"
            :fields="fields"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :current-page="1"
            :per-page="perPage"
            responsive
            hover
            style="vertical-align: middle; text-align: center"
            class="mx-0 p-0"
            :class="mobile ? 'mb-5 mt-0' : 'my-5'"
        >
            <template #cell(email_verified_at)="row">
                <div>
                    <span v-if="row.item.group" class="svg-icon svg-icon-sm svg-icon-primary">
                        <inline-svg src="/media/svg/icons/Navigation/Check.svg" />
                    </span>
                    <span v-else class="svg-icon svg-icon-sm svg-icon-danger">
                        <inline-svg src="/media/svg/icons/Navigation/Close.svg" />
                    </span>
                </div>
            </template>
            <template #cell(role)="row">
                <div>
                    {{ `${row.item.role ? row.item.role : 'Utilisateur'}` }}
                </div>
            </template>
            <template #cell(customer)="row">
                <div class="d-flex flex-column justify-content-around align-items-center" v-if="row.item.customer">
                    {{ row.item.customer.name }}
                    <span class="text-muted"> ( {{ row.item.customer.code }} ) </span>
                </div>
            </template>
            <template #cell(Action)="row">
                <div class="d-flex flex-column justify-content-around align-items-center">
                    <router-link :to="{ name: 'user/assign', params: { user: row.item } }" replace>
                        <button
                            class="btn btn-clean btn-sm svg-icon svg-icon-primary"
                            v-b-tooltip.hover.left="'Gestion des inscriptions à une classe'"
                        >
                            <inline-svg src="/media/svg/icons/General/Attachment1.svg" />
                        </button>
                    </router-link>
                </div>
            </template>
        </b-table>
        <div class="d-flex align-items-end" :class="mobile ? 'justify-content-center' : 'justify-content-between'" style="width:100%">
            <b-col sm="5" md="4">
                <b-pagination v-model="currentPage" :total-rows="trainees.meta ? trainees.meta.total : 1" :per-page="perPage" align="fill">
                </b-pagination>
            </b-col>
            <div>
                <ul class="pagination" :class="mobile ? 'mt-2' : ''">
                    <li class="page-item active">
                        <a class="page-link bg-primary"
                            >Nombre de résultats : {{ this.trainees.meta && this.trainees.meta.total ? this.trainees.meta.total : '' }}</a
                        >
                    </li>
                </ul>
            </div>
            <b-form-group
                hidden
                label="Nombre de résultats par page"
                label-for="perPageSelect"
                class="m-0 mr-2"
                :class="mobile ? 'text-center' : ''"
            >
                <b-form-select v-model="perPage" id="perPageSelect" size="sm" :options="pageOptions" />
            </b-form-group>
        </div>
    </div>
</template>

<script>
import { LIST_TRAINEE } from '@/core/services/store/api/trainee.service';
import { mapGetters } from 'vuex';
//import moment from 'moment';

export default {
    data() {
        return {
            currentPage: 1,
            perPage: 10,
            pageOptions: [],
            filter: null,
            isBusy: false,
            sortBy: 'lastname',
            sortDesc: false,
            fields: [
                { key: 'firstname', label: 'Prénom', sortable: true },
                { key: 'lastname', label: 'Nom', sortable: true },
                { key: 'customer', label: 'Établissement', sortable: true },
                { key: 'Action', label: 'Actions', sortable: false }
            ],
            form: {
                firstname: null,
                lastname: null,
                date_of_birth: null,
                email: null
            }
        };
    },
    computed: {
        ...mapGetters({ trainees: 'getTrainee' }),
        mobile() {
            return window.screen.availWidth > 992 ? false : true;
        }
    },
    mounted() {
        this.$store.dispatch(LIST_TRAINEE, { page: this.currentPage, filter: this.filter }).then(result => {
            this.perPage = result.data.meta.per_page;
            this.pageOptions.push(result.data.meta.per_page, this.filter);
        });
    },
    updated() {
        if (this.currentPage != this.trainees.meta.current_page) {
            this.$store.dispatch(LIST_TRAINEE, { page: this.currentPage, filter: this.filter });
        }
    },
    methods: {
        /*
        formatDate(value) {
            if (value) {
                return moment(String(value)).format('DD/MM/YYYY');
            }
        },
        */
        onSearch() {
            this.$store.dispatch(LIST_TRAINEE, { page: '1', filter: this.filter });
        }
    }
};
</script>
<style>
td {
    vertical-align: middle !important;
}
</style>
