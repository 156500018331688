<template>
    <div>
        <div
            id="table"
            class="border-0 m-0 d-flex flex-wrap align-items-center"
            :class="mobile ? 'justify-content-center' : 'justify-content-between'"
        >
            <div
                class="border-0 m-0 d-flex flex-wrap align-items-center mb-5"
                :class="mobile ? 'justify-content-center' : 'justify-content-between'"
                style="width:100%"
            >
                <b-form-group :style="mobile ? 'width:100%' : 'width:60%'" class="m-0" label-for="filterInput">
                    <b-input-group>
                        <b-form-input v-model="filter" type="search" id="filterInput" placeholder="Rechercher"> </b-form-input>
                        <b-input-group-append>
                            <b-button :disabled="!filter" @click="filter = ''">Effacer</b-button>
                        </b-input-group-append>
                    </b-input-group>
                </b-form-group>
                <router-link to="classes/new" replace>
                    <span class="btn btn-outline-primary btn-md " :class="mobile ? 'mt-5' : 'jmt-2'">
                        Créer une classe
                    </span>
                </router-link>
            </div>
            <b-table
                :items="classes"
                :fields="fields"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter="filter"
                @filtered="onFiltered"
                :current-page="currentPage"
                :per-page="perPage"
                responsive
                hover
                no-border-collapse
                style="vertical-align: middle; text-align: center"
                class="mx-0 p-0"
                :class="mobile ? 'mb-5 mt-0' : 'my-5'"
            >
                <template #cell(goal)="row">
                    <div>{{ row.item.goal === null ? 'Inderterminé' : row.item.goal + '%' }}</div>
                </template>
                <template #cell(linear)="row">
                    <div>
                        <span v-if="row.item.linear"> oui </span>
                        <span v-else> non </span>
                    </div>
                </template>
                <template #cell(on)="row">
                    <div>
                        <span v-if="row.item.on"> Disponible </span>
                        <span v-else> Indisponible </span>
                    </div>
                </template>
                <template #cell(created_at)="row">
                    {{ formatDate(row.item.created_at) }}
                </template>
                <template #cell(updated_at)="row">
                    {{ formatDate(row.item.updated_at) }}
                </template>
                <template #cell(theme)="row">
                    {{ row.item.themeName }}
                </template>
                <template #cell(classroom)="row">
                    <span v-if="row.item.classroom" class="svg-icon svg-icon-primary">
                        <inline-svg src="/media/svg/icons/classrooms.svg" />
                    </span>
                </template>
                <template #cell(Action)="row">
                    <div class="d-flex flex-column justify-content-around align-items-center">
                        <router-link :to="{ name: 'classes/edit', params: { id: row.item.id } }" replace>
                            <button class="btn btn-icon btn-circle btn-light btn-sm">
                                <span class="svg-icon svg-icon-md svg-icon-dark">
                                    <inline-svg src="/media/svg/icons/General/Visible.svg"></inline-svg>
                                </span>
                            </button>
                        </router-link>
                    </div>
                </template>
            </b-table>
            <b-row
                class="d-flex align-items-center"
                :class="mobile ? 'justify-content-center' : 'justify-content-between'"
                style="width:100%"
            >
                <b-col sm="5" md="4">
                    <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="fill"> </b-pagination>
                </b-col>
                <div>
                    <ul class="pagination" :class="mobile ? 'mt-2' : ''">
                        <li class="page-item active">
                            <a class="page-link bg-primary">Nombre de résultats : {{ this.classes ? this.classes.length : '' }}</a>
                        </li>
                    </ul>
                </div>
                <b-form-group
                    hidden
                    label="Nombre de résultats par page"
                    label-cols-sm="5"
                    label-cols-md="5"
                    label-cols-lg="5"
                    label-align-sm="center"
                    label-size="sm"
                    label-for="perPageSelect"
                    style="vertical-align : middle"
                    class="mr-2 align-items-center text-center"
                    :class="mobile ? 'text-center' : ''"
                >
                    <b-form-select v-model="perPage" id="perPageSelect" size="sm" :options="pageOptions"> </b-form-select>
                </b-form-group>
            </b-row>
        </div>
    </div>
</template>

<script>
import { LIST_ALL_CLASSES } from '@/core/services/store/api/classe.service';
import { mapGetters } from 'vuex';
import moment from 'moment';

export default {
    data() {
        return {
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [10, 25, 50, { value: 100, text: 'Le plus possible' }],
            filter: null,
            isBusy: false,
            sortBy: 'id',
            sortDesc: false,
            hasRight: false,
            fields: [
                { key: 'title', label: 'Titre', sortable: true },
                { key: 'goal', label: 'Objectif', sortable: true },
                { key: 'created_at', label: 'Date de création', sortable: true },
                { key: 'updated_at', label: 'Date MAJ', sortable: true },
                { key: 'version', label: 'Version', sortable: true },
                { key: 'linear', label: 'Parcours ordonné', sortable: true },
                { key: 'on', label: 'Statut en ligne', sortable: true },
                { key: 'theme', label: 'Thème de formation', sortable: true },
                { key: 'classroom', label: 'Classroom', sortable: true },
                { key: 'Action', label: '', sortable: false }
            ]
        };
    },
    computed: {
        ...mapGetters({ classes: 'getAllClasses' }),
        mobile() {
            return window.screen.availWidth > 992 ? false : true;
        }
    },
    async mounted() {
        await this.$store.dispatch(LIST_ALL_CLASSES);
        // Set the initial number of items
        this.totalRows = this.classes.length;
        // Vérifie qu'on possède le droit '1'
        if (this.$store.getters.currentUser.rights.filter(r => r === 1).length > 0) {
            this.hasRight = true;
        }
    },
    updated() {
        if (this.filteredItems) {
            if (this.filteredItems.length == 0) {
                this.totalRows = this.classes.length;
            }
        }
    },
    methods: {
        formatDate(value) {
            if (value) {
                return moment(String(value)).format('DD/MM/YYYY');
            }
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        }
    }
};
</script>
<style scoped>
.scrollPanel::-webkit-scrollbar-track {
    background-color: #eee;
}

.scrollPanel::-webkit-scrollbar {
    width: 5px;
    background-color: #eee;
}

.scrollPanel::-webkit-scrollbar-thumb {
    background-color: #085a82;
}
</style>
