<template>
    <div class="p-3">
        <h3 class="mb-4">Paramètres</h3>
        <div class="d-flex row">
            <router-link to="settings/questions_interactives" class="card d-flex flex-row justify-content-between align-items-center m-2" :style="mobile() ? '' : 'width: 20%'">
                <div class="left w-25 h-100 d-flex justify-content-center align-items-center">
                    <b-icon icon="question-square" font-scale="4.5"></b-icon>
                </div>
                <div class="right w-75 p-2">
                    <p class="m-0 text-dark">Questions Interactives</p>
                    <p class="m-0 mt-1 text-muted">Paramètrez les listes des questions interactives</p>
                </div>
            </router-link>
        </div>
    </div>
</template>

<script>
export default {
    methods: {
        mobile() {
            if (window.screen.availWidth <= 992) {
                return true;
            } else {
                return false;
            }
        }
    }
}

</script>
<style>

</style>
