<template>
    <div class="card card-custom col-lg-13 col-xxl-12 mt-5">
        <div v-if="loading" class="d-flex flex-column justify-content-center align-items-center" style="height: 82vh !important">
            <img :src="loaderLogo" style="max-width: 15%; height: auto" alt="Academy Si2p Logo" />
            <div class="spinner-primary spinner-border" style="width: 3rem; height: 3rem;" />
        </div>
        <div v-else>
            <div
                v-if="
                    (this.$route.query.activeTable !== 'stagiaires' &&
                        this.$route.query.activeTable !== 'clients' &&
                        this.$route.query.activeTable !== 'settings') ||
                    !this.$route.query.activeTable
                "
            >
                <ClassesManagerTable v-if="$store.getters.currentUser.role === 'gestionnaire'"></ClassesManagerTable>
                <b-tabs v-else-if="$store.getters.currentUser.role === 'admin'" small card v-model="activeDisplay">
                    <b-tab v-on:click="SetDisplay(0)" key="0">
                        <template #title>
                            <span class="svg-icon svg-icon-md svg-icon-primary" v-b-tooltip.hover.top="'Affichage Vignettes'">
                                <inline-svg src="/media/svg/icons/Layout/Layout-left-panel-1.svg" />
                            </span>
                        </template>
                        <ClassesThumbnail></ClassesThumbnail>
                    </b-tab>
                    <b-tab v-on:click="SetDisplay(1)" key="1">
                        <template #title>
                            <span class="svg-icon svg-icon-md svg-icon-primary" v-b-tooltip.hover.top="'Affichage Liste'">
                                <inline-svg src="/media/svg/icons/Layout/Layout-left-panel-2.svg" />
                            </span>
                        </template>
                        <ClassesTable></ClassesTable>
                    </b-tab>
                </b-tabs>
            </div>
            <div id="stagiaire" v-if="this.$route.query.activeTable === 'stagiaires'">
                <TraineeManagerTable v-if="this.$store.getters.currentUser.role === 'gestionnaire'"></TraineeManagerTable>
                <TraineeTable v-else-if="this.$store.getters.currentUser.role === 'admin'"></TraineeTable>
            </div>
            <div
                id="clients"
                v-if="this.$route.query.activeTable === 'clients' && this.$store.getters.currentUser.role === 'admin'"
                class="d-flex flex-column justify-content-center"
            >
                <ClientTable></ClientTable>
            </div>
            <div
                id="settings"
                v-if="this.$route.query.activeTable === 'settings' && this.$store.getters.currentUser.role === 'admin'"
                class="d-flex flex-column justify-content-center"
            >
                <SettingsTable></SettingsTable>
            </div>
        </div>
    </div>
</template>

<script>
import ClassesTable from '@/view/content/widgets/admin/ClassesTableWidget.vue';
import ClassesManagerTable from '@/view/content/widgets/manager/ClassesManagerTableWidget.vue';
import TraineeManagerTable from '@/view/content/widgets/manager/TraineesManagerTableWidget.vue';
import TraineeTable from '@/view/content/widgets/admin/TraineeTableWidget.vue';
import ClientTable from '@/view/content/widgets/admin/ClientTableWidget.vue';
import SettingsTable from '@/view/content/widgets/admin/SettingsTableWidget.vue';
import ClassesThumbnail from '@/view/content/widgets/admin/ClassesThumbnail.vue';
import { mapGetters } from 'vuex';

export default {
    name: 'admin_dashboard',
    data() {
        return {
            activeDisplay: 0,
            loading: true,
            pageOn: null,
            loading_time: 1500
        };
    },
    components: {
        ClassesThumbnail,
        ClassesTable,
        TraineeTable,
        TraineeManagerTable,
        ClientTable,
        ClassesManagerTable,
        SettingsTable
    },
    mounted() {
        if (this.$store['admin-dashboard-display'] === undefined) {
            this.$store['admin-dashboard-display'] = 0;
        }
        this.activeDisplay = this.$store['admin-dashboard-display'];
        this.pageOn = this.$route.query.activeTable;
        setTimeout(() => {
            this.loading = false;
        }, this.loading_time);
    },
    updated() {
        if (this.$route.query.activeTable !== this.pageOn && this.pageOn) {
            this.pageOn = this.$route.query.activeTable;
            this.loading = true;
            setTimeout(() => {
                this.loading = false;
            }, this.loading_time);
        }
    },
    methods: {
        SetDisplay(displayIndex) {
            this.$store['admin-dashboard-display'] = displayIndex;
        }
    },
    computed: {
        ...mapGetters(['layoutConfig']),

        loaderLogo() {
            return process.env.BASE_URL + this.layoutConfig('loader.logo');
        }
    }
};
</script>
